
import Vue from 'vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import { TOGGLE_LOADING, TOGGLE_SNACK } from '@/plugins/vuex/mutationTypes';
import { LOAD_PROFILE } from '@/plugins/vuex/modules/profile/actionTypes';
import { LOAD_ENGAGEMENT_INFO } from '@/plugins/vuex/modules/engagementInfo/actionTypes';
import { LOAD_FEATURE_FLAGS } from '@/plugins/vuex/modules/featureFlags/actionTypes';
import TTView from '@/components/ui/TTView.vue';
import TTLoader from '@/components/ui/TTLoader.vue';
import isExternalExpert from '@/services/cookies/isExternalExpert';

export default Vue.extend({
  name: 'PlatformLayout',

  components: {
    TTView,
    TTLoader,
  },

  data() {
    return {
      showToTopBtn: false,
    };
  },

  computed: {
    ...mapState({
      snackbar: 'snackbar',
      loading: 'loading',
      navDrawer: 'navDrawer',
    }),

    showBackButton(): boolean {
      return !this.$route?.meta?.hideBackButton;
    },
    fixedPadding(): boolean {
      return this.$route?.meta?.fixedPadding;
    },
  },

  async created() {
    this[TOGGLE_LOADING](true);
    try {
      await this[LOAD_FEATURE_FLAGS]();
      if (!isExternalExpert.get()) {
        await this[LOAD_PROFILE]();
        await this[LOAD_ENGAGEMENT_INFO]();
      }
    } catch (e) {
      // TODO: Написать обработку ошибок
    } finally {
      this[TOGGLE_LOADING](false);
    }
  },

  methods: {
    ...mapMutations([TOGGLE_LOADING, TOGGLE_SNACK]),
    ...mapActions('profile', [LOAD_PROFILE]),
    ...mapActions('engagementInfo', [LOAD_ENGAGEMENT_INFO]),
    ...mapActions('featureFlags', [LOAD_FEATURE_FLAGS]),
    onScroll(e: Event) {
      if (typeof window === 'undefined') return;
      const top = window.pageYOffset || (e.target as HTMLElement).scrollTop || 0;
      this.showToTopBtn = top > 200;
    },
    scrollToTop() {
      this.$vuetify.goTo(0);
    },
  },
});
